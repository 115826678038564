import React, { useEffect, useState } from "react";
import classNames from "classnames";
import t from "@utils/t";
import LogoEn from "@svg/logo.svg";
import LogoFr from "@svg/logoFr.svg";

const IntentionShare = ({
  shareRef,
  color,
  backgroundColor,
  artwork,
  background,
  intention,
  pageLang,
  artist,
  forInstagram,
}) => {
  const toDataURL = url =>
    fetch(url)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const [backgroundImage, setBakgroundImage] = useState();

  useEffect(() => {
    const getDataBackgroundImage = async imageUrl => {
      const base64 = await toDataURL(imageUrl);
      setBakgroundImage(`url(${base64})`);
    };
    getDataBackgroundImage(background || artwork);
  }, []);

  return (
    <div
      className="pointer-events-none fixed left-[-1000vw] top-[-1000vh] z-[200] opacity-0"
      aria-hidden
    >
      <div
        ref={shareRef}
        className={classNames(
          "relative flex w-[540px] items-center justify-center",
          {
            "h-[540px]": !forInstagram,
            "h-[960px]": forInstagram,
          }
        )}
        style={{
          backgroundImage,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="flex h-full w-full items-center justify-center bg-black/25">
          <div
            className={classNames(
              "relative z-10 flex aspect-playing-card w-[288.93px] flex-col items-center justify-between gap-4 overflow-hidden rounded-2xl px-4 py-8",
              {
                "scale-[1.4]": forInstagram,
              }
            )}
            style={{
              backgroundColor,
            }}
          >
            <div
              className="relative aspect-portal w-[91px] overflow-hidden rounded-t-full"
              style={{
                backgroundImage,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />

            <h3
              className={classNames(
                "block w-full px-4 text-center font-magilio",
                {
                  "text-[24px]": intention?.length >= 70,
                  "text-[27px]":
                    intention?.length > 36 && intention?.length < 70,
                  "text-[32px]": intention?.length <= 36,
                }
              )}
              style={{
                color,
              }}
            >
              {intention}
            </h3>

            <div>
              {(pageLang === "en" || pageLang === "es") && (
                <span className="icon--fit-height h-[30px] overflow-visible">
                  <LogoEn />
                </span>
              )}
              {pageLang === "fr" && (
                <span className="icon--fit-height h-[30px] overflow-visible">
                  <LogoFr />
                </span>
              )}
            </div>
          </div>

          <span
            className={classNames("absolute text-[13px] font-bold", {
              "bottom-[55px] translate-y-6": !forInstagram,
              "bottom-[155px] scale-[1.2]": forInstagram,
            })}
            style={{
              color: backgroundColor,
            }}
          >{`${t("Artwork by ", pageLang)} ${artist.title}`}</span>
        </div>
      </div>
    </div>
  );
};

IntentionShare.defaultProps = {};

export default IntentionShare;
