import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Text, Icon, Image, Container, Button } from "@atoms";
import { ArchFrame, Socials } from "@molecules";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { toggleBodyScroll } from "@utils";

const ImageModalSlide = ({
  heading,
  copy,
  image,
  bodyImage,
  backgroundImage,
  previousText,
  nextText,
  pageState,
  visibleState,
  shortTitle,
  length,
  button,
  socials,
}) => {
  const lang = useLang();
  const scroll = useRef();

  useEffect(() => {
    toggleBodyScroll(true, scroll.current);
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentPage, setCurrentPage] = pageState || useState(0);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [, setVisible] = visibleState || useState(true);
  return (
    <div className="absolute inset-0 flex flex-col bg-black">
      <div
        ref={scroll}
        className="relative z-0 flex flex-grow flex-col justify-between gap-6 overflow-y-auto px-8 py-10 sm:py-20 md:flex-row md:px-20"
      >
        {backgroundImage && (
          <Image
            className="pointer-events-none !fixed inset-0 -z-10 object-cover opacity-20"
            image={backgroundImage}
            fill
            draggable={false}
          />
        )}
        <div className="relative z-10 flex flex-col justify-start md:justify-between">
          <Text variant="h2" className="mb-10 max-w-xl text-white">
            {heading}
          </Text>
          {(button?.url || !!socials?.length) && (
            <div className="mb-10 flex flex-wrap items-center gap-3">
              {button?.url && (
                <Button size="xs" color="viridian" to={button.url}>
                  {button.text}
                </Button>
              )}
              {!!socials?.length && (
                <Socials color="viridian" socials={socials} />
              )}
            </div>
          )}
          <div className="flex flex-col justify-between md:flex-row md:items-end md:justify-between">
            <div className="max-w-lg pr-5">
              {bodyImage && (
                <div className="mb-5 w-full">
                  <Image
                    aspectRatio={[1.56, 1]}
                    ixParams={{ fit: "crop" }}
                    image={bodyImage}
                    caption={
                      image.attribution
                        ? `${t("Photo courtesy of", lang)} ${image.attribution}`
                        : null
                    }
                  />
                </div>
              )}
              <Text className="prose block text-white" richText>
                {copy}
              </Text>
              <div className="h-10 md:h-40" />
            </div>
            {!image && backgroundImage?.attribution && (
              <div className="relative z-20 flex flex-col items-end text-white">
                <Text variant="caption">{t("Photo courtesy of", lang)}</Text>
                <Text className="whitespace-nowrap" variant="caption">
                  {backgroundImage.attribution}
                </Text>
              </div>
            )}
          </div>
        </div>
        <div className="relative">
          {image && (
            <ArchFrame
              caption={image.attribution}
              className="mx-auto w-80 text-white md:mx-0 md:w-96"
              captionAlign="right"
            >
              <Image image={image} fill draggable={false} />
            </ArchFrame>
          )}
        </div>
      </div>
      <div className="relative z-10 w-full flex-shrink-0 border-t-3 border-white bg-black">
        <div className="pointer-events-none absolute bottom-[calc(100%_+_3px)] left-0 right-0 z-10 h-40 rotate-180 bg-gradient-to-b from-black" />
        <Container variant="xl">
          <div className="flex flex-nowrap justify-between text-white">
            <button
              type="button"
              aria-label={`Go to the ${previousText} slide`}
              className={classNames(
                "md:w-54 flex items-center gap-3 px-6 py-4 sm:py-7",
                {
                  "pointer-events-none opacity-0": currentPage === 0,
                }
              )}
              onClick={() =>
                setCurrentPage(previousState =>
                  previousState > 0 ? previousState - 1 : previousState
                )
              }
            >
              <Icon name="backwardsArrow" className="h-4 w-4 shrink-0" />
              <Text variant="button" className="hidden md:block">
                {previousText || t("Previous Story", lang)}
              </Text>
            </button>
            <button
              type="button"
              className="p-3 sm:p-4"
              aria-label={`Close the ${shortTitle} tab`}
              onClick={() => {
                setVisible(false);
              }}
            >
              <Icon name="close" className="h-4 w-4 sm:h-6 sm:w-6" />
            </button>
            <button
              type="button"
              aria-label={`Go to the ${nextText} slide`}
              className={classNames(
                "md:w-54 flex items-center gap-3 px-6 py-4 sm:py-7",
                {
                  "pointer-events-none opacity-0": currentPage === length - 1,
                }
              )}
              onClick={() =>
                setCurrentPage(previousState =>
                  previousState < length - 1 ? previousState + 1 : previousState
                )
              }
            >
              <Text variant="button" className="hidden md:block">
                {nextText || t("Next Story", lang)}
              </Text>
              <Icon name="forwardArrow" className="h-4 w-4 shrink-0" />
            </button>
          </div>
        </Container>
      </div>
    </div>
  );
};

ImageModalSlide.defaultProps = {};

export default ImageModalSlide;
