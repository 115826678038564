import React from "react";
import { Text, Button, Container } from "@atoms";
import { AppLink } from "@base";
import classNames from "classnames";
import { m } from "framer-motion";
// import tailwindConfig from "@theme";
import { useWindowSize } from "@utils";

const AccordionItem = ({ header, copy, list, button, i, state: _state }) => {
  const [open, setOpen] = _state;
  const { innerWidth: windowWidth } = useWindowSize();
  const { screens } = {
    xxs: { max: "350px" }, // for super small screens
    sm: "700px", // bigger than most phones
    md: "850px",
    lg: "1200px",
    xl: "1600px", // larger than 15" macbook pro
    xxl: "2000px",
  };
  const isMobile = windowWidth <= screens?.md.replace("px", "");

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames(
        "relative w-full cursor-default py-8 transition-colors duration-200",
        {
          "bg-viridian": open === i,
          "bg-black": open !== i,
          "border-t-3 border-white": i !== 0,
        }
      )}
      aria-label={`Expand accordion containing information on ${header}`}
      onMouseEnter={() => {
        if (!isMobile) setOpen(i);
      }}
      onClick={() => {
        if (isMobile) setOpen(i);
      }}
    >
      <div className="relative z-10">
        <Container
          variant="lg"
          className="flex flex-col items-start justify-start gap-6"
        >
          <Text variant="h5" className="flex-shrink-0 text-left text-white">
            {header}
          </Text>

          {list?.length > 0 && (
            <ul className="flex flex-wrap gap-4 divide-x-2 divide-white">
              {list?.map((item, _i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li className="pl-4 first:pl-0" key={_i}>
                    <AppLink to={item?.url}>
                      <Text variant="buttonSmall" className="text-white">
                        {item?.text}
                      </Text>
                    </AppLink>
                  </li>
                );
              })}
            </ul>
          )}
        </Container>
      </div>
      <m.div
        className="pointer-events-none relative z-10 overflow-hidden"
        initial={{ height: 0 }}
        animate={{ height: open === i ? "auto" : 0 }}
        transition={{
          height: { ease: [0.83, 0, 0.17, 1], duration: 0.2 },
        }}
      >
        <Container className="flex flex-wrap items-center justify-between gap-4 py-10">
          <Text
            className="max-w-xs text-left font-bold text-white"
            variant="sm"
            dewidow
          >
            {copy}
          </Text>
          <Button to={button?.url} color="black" size="sm" icon="forwardArrow">
            {button?.text}
          </Button>
        </Container>
      </m.div>
      {button?.url && (
        <AppLink
          to={button?.url}
          className={classNames("absolute inset-0 z-0", {
            "pointer-events-none": open !== i,
          })}
          ariaHidden="true"
        />
      )}
    </div>
  );
};

AccordionItem.defaultProps = {};

export default AccordionItem;
