import React, { useState } from "react";
import { animate, m } from "framer-motion";
import { Text } from "@atoms";

const ComponentName = ({ stat, label, className }) => {
  const [animatedNumber, setAnimatedNumber] = useState(0);

  const otherbits = stat.replace(/[0-9]/g, "");
  const isMoney = otherbits === "$";

  // Animate the numbers
  const updateNumbers = numberTo => {
    animate(0, numberTo, {
      duration: Math.min(numberTo / 3, 3),
      onUpdate: value => {
        setAnimatedNumber(value.toFixed());
      },
      transition: {
        ease: "circIn",
      },
    });
  };
  return (
    <m.li
      className={className}
      viewport={{ once: true, amount: "all", margin: "20px 0px 20px 0px" }}
      onViewportEnter={() => updateNumbers(parseInt(stat.replace("$", ""), 10))}
    >
      <Text variant="metric" className="text-center text-white">
        {`${
          isMoney
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                trailingZeroDisplay: "stripIfInteger",
              }).format(animatedNumber)
            : animatedNumber
        }${!isMoney ? otherbits : ""}`}
      </Text>
      <Text variant="metricLabel" className="text-center text-white">
        {label}
      </Text>
    </m.li>
  );
};

export default ComponentName;
