import React from "react";
import { Text } from "@atoms";
import useLang from "@hooks/useLang";
import t from "@utils/t";
import classNames from "classnames";

const ArchFrame = ({ children, caption, className, captionAlign }) => {
  const lang = useLang();
  return (
    <div className={className}>
      <div className="relative overflow-hidden rounded-t-full bg-black pb-[133%]">
        {children}
      </div>
      {caption && (
        <div
          className={classNames(
            "mt-6 flex min-w-[10rem] max-w-[33%] flex-wrap gap-1",
            {
              "justify-start": captionAlign === "left",
              "ml-auto justify-end": captionAlign === "right",
            }
          )}
        >
          <Text variant="caption">{t("Photo courtesy of", lang)}</Text>
          <Text className="whitespace-nowrap" variant="caption">
            {caption}
          </Text>
        </div>
      )}
    </div>
  );
};

ArchFrame.defaultProps = {
  captionAlign: "left",
};

export default ArchFrame;
