import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock-upgrade";

const toggleBodyScroll = (enable, element) => {
  if (enable) {
    enableBodyScroll(element);
  } else {
    disableBodyScroll(element);
  }
};

export default toggleBodyScroll;
