/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useWindowSize } from "@utils";
import { m } from "framer-motion";
import Text from "./Text";

const buildUrl = (url, params) =>
  `${url.replace(
    process.env.GATSBY_CDN_BASE_URL,
    process.env.GATSBY_IMGIX_BASE_URL
  )}?${Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`;

const Image = ({
  image,
  ixParams,
  objectFit,
  // width,
  // height,
  caption,
  transparent,
  alt,
  ratio,
  backgroundColor,
  eager,
  resolution,
  fill,
  aspectRatio,
  widthAdjust: _widthAdjust,
  preserveAspectRatio,
  draggable,
  className,
  fast,
  sourceArray,
}) => {
  if (!image) return null;

  const figure = useRef();
  const { innerWidth: windowSize } = useWindowSize();
  const [inView, setInView] = useState(false);
  const [loaded, setLoaded] = useState(eager);
  const [widthAdjust, setWidthAdjust] = useState(_widthAdjust);
  const [sources, setSources] = useState([]);
  const [pr, setPr] = useState(resolution || 1);
  const { url, height, width, focalPoint, skipBuild } = image || {};
  const [fpx, fpy] = focalPoint || [false, false];
  const ar = aspectRatio ? aspectRatio[0] / aspectRatio[1] : width / height;

  useEffect(() => {
    setPr(resolution || window.devicePixelRatio);
  }, []);

  const defaultParams = {
    auto: eager ? "compress" : "compress,format",
    fit: "crop",
    q: pr > 1.75 ? 90 : 90,
    crop: "faces",
    w: 360, // from state
    h: parseInt(ar ? 360 : 360 * ar, 10), //  from state
    dpr: pr > 2 ? 2 : pr,
  };

  const finalParams = { ...defaultParams, ...ixParams };

  if (fpx && fpy) {
    finalParams["fp-x"] = fpx;
    finalParams["fp-y"] = fpy;
    finalParams.fit = "crop";
    finalParams.crop = "focalpoint";
  }

  // const baseUrl = !skipBuild
  //   ? buildUrl(url, {
  //       ...finalParams,
  //       w: parseInt(400 * widthAdjust, 10),
  //       h: parseInt(ar ? ar * 400 * widthAdjust : 400 * widthAdjust, 10),
  //     })
  //   : url;

  useEffect(() => {
    requestAnimationFrame(() => {
      let wa = _widthAdjust;
      if (figure.current) {
        wa = (figure.current.clientWidth / windowSize).toFixed(1) || 0.1;
        setWidthAdjust(wa);
      }
    });
  }, [figure.current, windowSize, inView]);

  useEffect(() => {
    const arm = preserveAspectRatio
      ? 1 / ar
      : (figure.current.clientHeight / figure.current.clientWidth).toFixed(2);

    setSources(
      [360, 500, 800, 1360, 2000].map(size => ({
        url: !skipBuild
          ? buildUrl(url, {
              ...finalParams,
              w: parseInt(size * widthAdjust, 10) || size,
              h:
                parseInt(
                  ar ? arm * size * widthAdjust : size * widthAdjust,
                  10
                ) || size,
            })
          : url,
        size,
      }))
    );
  }, [widthAdjust, url, pr]);

  // load in images after 1 a second
  useEffect(() => {
    setTimeout(() => {
      setInView(true);
    }, 1000);
  }, []);

  return (
    <figure
      ref={figure}
      className={classNames(className, "flex w-full flex-col duration-100", {
        "absolute inset-0": fill,
        relative: !fill,
      })}
    >
      <div
        className={classNames({ "absolute inset-0": fill })}
        style={{
          backgroundColor,
          paddingTop: 0,
          paddingBottom: fill
            ? 0
            : `${((1 / ar || height / width) * 100).toFixed(2)}%`,
        }}
      >
        <m.picture
          initial={eager || fast ? {} : { opacity: 0 }}
          className={classNames("h-full w-full")}
          animate={loaded && !eager ? { opacity: 1 } : false}
          viewport={{ once: true }}
          onViewportEnter={() => setInView(true)}
        >
          {/* load srcset */}
          {(inView || eager) && (
            <>
              {sources.map((s, i) => (
                <source
                  // eslint-disable-next-line react/no-array-index-key
                  key={s.url}
                  srcSet={s.url}
                  media={`(max-width: ${sources?.[i + 1]?.size || 4000}px)`}
                />
              ))}
            </>
          )}
          {/* todo: fix alt tag fallback */}
          <img
            width="100%"
            height="100%"
            onLoad={() => setLoaded(true)}
            // src={inView || eager ? baseUrl : null}
            draggable={draggable}
            alt={alt || "image"}
            className={classNames("absolute inset-0 h-full w-full", {
              "object-cover": objectFit === "cover",
              "object-contain": objectFit === "contain",
            })}
          />
        </m.picture>
      </div>
      {caption && (
        <figcaption className="absolute top-full z-30 pt-4">
          <Text variant="caption" className="text-white">
            {caption}
          </Text>
        </figcaption>
      )}
    </figure>
  );
};

Image.defaultProps = {
  objectFit: "cover",
  eager: false,
  widthAdjust: 1,
  draggable: true,
};

export default Image;
