import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { Image } from "@atoms";
import useMousePosition from "@hooks/useMousePosition";
import {
  m,
  useMotionValue,
  useTransform,
  cubicBezier,
  useInView,
} from "framer-motion";

const IntentionsProfileArtwork = ({ art, i, align }) => {
  const artworkRef = useRef(null);
  const isInView = useInView(artworkRef);

  // mouse positions
  const { x: mouseX, y: mouseY } = useMousePosition();
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const mappedMovement = {
    0: [
      // x output - pixs you wanna move
      [-6, 8],
      // y output - pixs you wanna move
      [-6, 8],
      // x input -- mouse pos
      [0, 1200],
      // y input -- mouse pos
      [1200, 0],
    ],
    1: [
      [-12, 12],
      [-12, 12],
      [1200, 0],
      [1200, 0],
    ],
    2: [
      [-9, 18],
      [-9, 13],
      [0, 1200],
      [0, 1200],
    ],
    3: [
      [-2, 2],
      [-2, 2],
      [1200, 0],
      [0, 1200],
    ],
    4: [
      [-9, 13],
      [-8, 18],
      [1200, 0],
      [0, 1200],
    ],
  };

  const [xOutput, yOutput, xInput, yInput] = mappedMovement[i];

  // TODO: general Output works, need to fine tune
  const offsetX = useTransform(x, xInput, xOutput, {
    clamp: false,
    ease: cubicBezier(0.17, 0.67, 0.83, 0.67),
  });

  const offsetY = useTransform(y, yInput, yOutput, {
    clamp: false,
    ease: cubicBezier(0.17, 0.67, 0.83, 0.67),
  });

  useEffect(() => {
    if (isInView) {
      x.set(mouseX);
      y.set(mouseY);
    }
  }, [mouseX, mouseY, isInView]);

  const move = isInView ? { x: offsetX, y: offsetY } : {};

  const imageSizes = {
    xl: "aspect-[777/434] w-[52.8vw] xl:w-[760px]",
    lg: "aspect-[727/406] w-[50.8vw] xl:w-[727px]",
    md: "aspect-[562/314] w-[39vw] xl:w-[562px]",
    sm: "aspect-[356/428] w-[24.7vw] xl:w-[356px]",
    xs: "aspect-[237/286] w-[15.9vw] xl:w-[237px]",
  };

  const imageLayout = {
    left: {
      0: imageSizes.lg,
      1: imageSizes.md,
      2: imageSizes.sm,
      3: imageSizes.xl,
      4: imageSizes.xs,
    },
    right: {
      0: imageSizes.lg,
      1: imageSizes.md,
      2: imageSizes.xs,
      3: imageSizes.xl,
      4: imageSizes.sm,
    },
  };

  const imagePostition = {
    left: {
      0: "-top-[4.3%] right-[100%]",
      1: "top-[3.6%] left-[58%]",
      2: "bottom-[0%] sm:bottom-[-6%] left-[3.8%] right-[80%] z-10",
      3: "bottom-[6%] sm:bottom-[0%] -left-[7.3%] right-0",
      4: "bottom-[22%] sm:bottom-[21%] -right-[0.8%] left-[78%] z-50",
    },
    right: {
      0: "-top-[4.3%] -left-[6.3%] right-[100%]",
      1: "top-[3.6%] -right-[1.5%] left-[58%]",
      2: "left-[7.6%] bottom-[13%] sm:bottom-[5%] right-[71.6%] z-40",
      3: "bottom-[8%] sm:-bottom-[2%] -left-[7.3%] right-0",
      4: "bottom-[14.6%] sm:bottom-[6.6%] -right-[6%] z-50 left-[78%]",
    },
  };
  return (
    <m.div
      ref={artworkRef}
      className={classNames(
        "absolute mx-auto",
        imageLayout[align]?.[i],
        imagePostition[align]?.[i]
      )}
      style={move}
    >
      <div className="h-full w-full scale-125 bg-black sm:scale-100 xxl:scale-125">
        <Image
          image={art}
          fill
          className={classNames({
            "opacity-60 md:opacity-70": i === 0 || i === 1 || i === 3,
            "opacity-75 md:opacity-100": i === 2 || i === 4,
          })}
          draggable={false}
        />
      </div>
    </m.div>
  );
};

IntentionsProfileArtwork.defaultProps = {};

export default IntentionsProfileArtwork;
