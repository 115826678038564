import React from "react";
import classNames from "classnames";
import { Text } from "@atoms";

const DeepDiveSlide = ({ color, heading, copy, align, caption }) => {
  const colors = {
    viridian: "bg-viridian",
    coral: "bg-coral",
  };
  const textStyles = {
    viridian: "text-white",
    coral: "text-black",
  };
  return (
    <div
      className={classNames(
        "texture-pattern relative h-[calc(100vh-4.2rem)] h-full w-full shrink-0 self-start py-10 md:max-w-xl",
        colors[color],
        textStyles[color],
        {
          "pl-4 lg:pl-12 xl:pl-8": align === "left",
          "pr-4 lg:pr-12 xl:pr-8": align === "right",
          "px-4 lg:px-12 xl:px-8": align === "center",
        }
      )}
    >
      <div
        className={classNames(
          "flex h-full w-full flex-col justify-between md:max-w-xs",
          {
            "mr-auto pr-10 md:pr-16": align === "left",
            "ml-auto pl-10 md:pl-16": align === "right",
            "mx-auto": align === "center",
          }
        )}
      >
        <div className="h-full">
          {heading && (
            <Text
              variant="h6"
              className={classNames("mb-6", {
                "text-viridian": color === "coral",
              })}
            >
              {heading}
            </Text>
          )}
          <Text variant="xl" dewidow>
            {copy}
          </Text>
        </div>
        {caption && (
          <Text variant="caption" className="mt-4 pb-16">
            {caption}
          </Text>
        )}
      </div>
    </div>
  );
};

DeepDiveSlide.defaultProps = {};

export default DeepDiveSlide;
