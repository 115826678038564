// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";

const useDevice = () => {
  const [device, setDevice] = useState("iOS");
  useEffect(() => {
    const iOS = () => {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    };

    if (!iOS()) {
      setDevice("other");
    }
  }, []);
  return device;
};

export default useDevice;
