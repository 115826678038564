// french translations
export default {
  "Learn more": "Learn more",
  "Photo courtesy of": "Photo courtesy of",
  Explore: "Explore",
  Download: "Download",
  "Previous Story": "Previous Story",
  "Next Story": "Next Story",
  "Expand Story": "Expand Story",
  Country: "Country",
  "Email Address": "Email Address",
  Submit: "Submit",
  Partners: "Partners",
  "Previous Chapter": "Previous Chapter",
  "Next Chapter": "Next Chapter",
  "Read the transcript": "Read the transcript",
  // intention:// intentions
  Flip: "Flip",
  Close: "Close",
  Share: "Share",
  "Artwork by ": "Artwork by ",
  "Learn About the Artist": "Learn About the Artist",
  "See more of their work at": "See more of their work at",
  "Share Instagram Story": "Share Instagram Story",
  "Download and Share": "Download and Share",
  Scroll: "Scroll",
  "Equality Fund homepage": "Equality Fund homepage",
  About: "About",
  "404 Page Not Found": "404 Page Not Found",
  "Looks like there's nothing here.": "Looks like there's nothing here.",
  // map
  Africa: "Africa",
  "Asia & Pacific": "Asia & Pacific",
  Caribbean: "Caribbean",
  Europe: "Europe",
  "Latin America": "Latin America",
  "Middle East": "Middle East",
  "Multi-Regional": "Multi-Regional",
  Region: "Region",
  "Organizations Funded": "Organizations Funded",
  "Amount Distributed in CAD": "Amount Distributed in CAD",
  "Organizations Funded Directly": "Organizations Funded Directly",
  "Feminist Funds": "Feminist Funds",
  "Organizations funded via our sister funds":
    "Organizations funded via our sister funds",
};
