import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Icon, Text } from "@atoms";
import { useAppState } from "@state";

import useDevice from "@hooks/useDevice";

const HomeAudioLinks = ({
  title,
  heading,
  subHeading,
  transcript,
  audio: url,
  iconSize,
  color,
  className: _className,
  hideOnMobile,
}) => {
  const [{ audio }, dispatch] = useAppState();
  const { play } = audio;
  const [playing, setPlaying] = useState(false);
  const device = useDevice();

  const openTranscript = () => {
    dispatch({
      type: "openTranscript",
      ...transcript,
    });
  };

  const setMedia = () => {
    dispatch({
      type: "setMedia",
      url,
      global: true,
      title,
    });
  };

  useEffect(() => {
    if (playing) {
      dispatch({
        type: "startAudio",
        url,
        global: true,
        title,
      });
    } else if (play && !playing) {
      dispatch({
        type: "pauseAudio",
      });
    }
  }, [playing]);

  useEffect(() => {
    if (!play && playing) {
      setPlaying(false);
    }
    if (play && !playing) {
      setPlaying(true);
    }
  }, [play]);

  return (
    <div
      className={classNames("flex items-start gap-3", _className, {
        "text-white": color === "white",
      })}
    >
      {device !== "iOS" && (
        <button
          className={classNames(
            "relative flex-shrink-0 flex-shrink-0 items-center justify-center rounded-full border-2 ",
            {
              "h-16 w-16": iconSize !== "sm",
              "h-10 w-10": iconSize === "sm",
              "border-viridian text-viridian": color !== "white",
              "border-white text-white": color === "white",
            }
          )}
          type="button"
          onClickCapture={() => setPlaying(s => !s)}
        >
          <span className="sr-only">{playing ? "pause" : "play"}</span>
          <Icon
            name="play"
            fitHeight
            className={classNames("absolute inset-0 transition duration-100", {
              "opacity-0": playing,
              "p-1.5": iconSize === "sm",
              "p-3": iconSize !== "sm",
            })}
          />
          <Icon
            name="pause"
            fitHeight
            className={classNames(
              "stroke absolute inset-0 stroke-current transition duration-100",
              {
                "opacity-0": !playing,
                "p-2": iconSize === "sm",
                "p-4": iconSize !== "sm",
              }
            )}
          />
        </button>
      )}
      {device === "iOS" && (
        <button
          className={classNames(
            "relative flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full border-2",
            {
              "border-viridian text-viridian": color !== "white",
              "border-white text-white": color === "white",
            }
          )}
          type="button"
          onClickCapture={setMedia}
        >
          <span className="sr-only">{heading}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-8 w-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
            />
          </svg>
        </button>
      )}
      <button
        onClick={openTranscript}
        type="button"
        className="max-w-[16rem] text-left"
      >
        {heading && (
          <Text
            className={classNames(
              "mb-2 block border-b-2 border-white pb-1 text-left text-lg font-bold uppercase md:w-max",
              {
                "block md:hidden": hideOnMobile,
              }
            )}
          >
            {heading}
          </Text>
        )}
        <Text tag="div" variant="xs" className="prose leading-normal">
          {subHeading}
        </Text>
      </button>
    </div>
  );
};

export default HomeAudioLinks;
