import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { useAppState } from "@state";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock-upgrade";

const Modal = () => {
  const modal = useRef();
  const content = useRef();
  const [{ modal: modalState }, dispatch] = useAppState();
  const {
    modal: open,
    content: Content,
    background: _bg,
    title,
    description,
    focusId,
    allowScroll,
  } = modalState;

  const [iOS, setIOS] = useState(false);

  const transition = {
    type: "tween",
    duration: 0.2,
  };

  const containerVariants = {
    open: {
      pointerEvents: "auto",
      opacity: 1,
      transition,
    },
    closed: {
      pointerEvents: "none",
      opacity: 0,
      transition,
    },
  };

  const contentVariants = {
    open: {
      opacity: 1,
      transition: {
        ...transition,
        type: "tween",
        tween: "easeOut",
      },
    },
    closed: {
      opacity: 0,
      transition: {
        ...transition,
        type: "tween",
        tween: "easeOut",
      },
    },
  };

  useEffect(() => {
    setIOS(
      ([
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)) &&
        // eslint-disable-next-line eqeqeq
        navigator.userAgent.search("Firefox") === -1 &&
        navigator.userAgent.search("FxiOS") === -1
    );
    const handleKey = e => {
      if (parseInt(e.keyCode, 10) === 27) {
        dispatch({ type: "closeModal" });
      }
    };
    document.addEventListener("keydown", handleKey);
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, []);

  useEffect(() => {
    if (modal.current) {
      if (!open) {
        if (!allowScroll) enableBodyScroll(modal.current);
        document.documentElement.classList.remove("is-locked");
        dispatch({ type: "closeModal" });
      }
    }
  }, [open]);

  return (
    <m.div
      initial={false}
      animate={open ? "open" : "closed"}
      variants={containerVariants}
      className={classNames("fixed inset-0 z-[800] flex", _bg, {
        "pointer-events-none": !open,
      })}
      onAnimationComplete={(a, b) => {
        if (!open && modal.content) {
          dispatch({ type: "clearModalContent" });
        } else if (open) {
          if (focusId) {
            document.getElementById(focusId).focus();
          }
          if (modal.current && !allowScroll) disableBodyScroll(modal.current);
          document.documentElement.classList.add("is-locked");
        }
      }}
    >
      <button
        type="button"
        className={classNames(
          "absolute inset-0 z-0 flex w-full items-center text-xs font-bold uppercase text-transparent",
          {}
        )}
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
        aria-label="Close modal"
      >
        <span className="sr-only">Close</span>
      </button>
      <m.div
        ref={modal}
        animate={open ? "open" : "closed"}
        className="relative z-30 flex-grow overflow-x-hidden overflow-y-scroll"
        variants={contentVariants}
        aria-labelledby={title ? "modalTitle" : null}
        aria-describedby={description ? "modalDescription" : null}
      >
        <div ref={content} className="h-full">
          {!!Content && (
            <>
              {title && (
                <h2 id="modalTitle" className="sr-only">
                  {title}
                </h2>
              )}
              {description && (
                <p id="modalDescription" className="sr-only">
                  {description}
                </p>
              )}
              <Content />
            </>
          )}
        </div>
      </m.div>
      <div className="safar_only relative flex-shrink-0" />
    </m.div>
  );
};

export default Modal;
