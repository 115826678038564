import React from "react";
import { Icon } from "@atoms";
import classNames from "classnames";

const NextArrow = ({ className, next }) => {
  return (
    <button
      type="button"
      onClick={() =>
        next
          ? next.current.scrollIntoView({ behavior: "smooth" })
          : window.scrollBy({
              top: window.innerHeight * 0.8,
              behavior: "smooth",
            })
      }
      className={classNames(
        "group flex flex h-10 w-10 shrink-0 items-center justify-center rounded-full border-2 border-white p-3 transition duration-300 hover:scale-105",
        className
      )}
    >
      <span className="sr-only">scroll</span>
      <Icon
        name="arrow"
        className="h-4 w-4 rotate-90 text-white transition duration-300 group-hover:scale-110"
        fitHeight
      />
    </button>
  );
};

export default NextArrow;
