import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { m } from "framer-motion";

const Section = ({ anchor, children, className }) => {
  const ref = useRef();

  useEffect(() => {
    const { hash } = window.location;
    setTimeout(() => {
      if (hash === `#${anchor}` && ref.current) {
        ref.current.scrollIntoView({
          // behavior: "smooth",
        });
      }
    }, 1000);
  }, [ref.current]);

  return (
    <m.section
      key={anchor}
      ref={ref}
      // todo: might want to use this? breaks with transition currently
      // onViewportEnter={() => {
      //   if (window.history.pushState) {
      //     window.history.pushState(null, null, `#${id}`);
      //   } else {
      //     window.location.hash = `#${id}`;
      //   }
      // }}
      className={classNames(
        className,
        "texture-pattern relative z-[100] bg-viridian"
      )}
      // id={id}
    >
      {children}
    </m.section>
  );
};

export default Section;
