// spanish translations
export default {
  "Learn more": "Aprender más",
  "Photo courtesy of": "Foto cortesía de",
  Explore: "Explorar",
  Download: "Descargar",
  "Previous Story": "Historia anterior",
  "Next Story": "Historia siguiente",
  "Expand Story": "Expandir historia",
  Country: "País",
  "Email Address": "Correo electrónico",
  Submit: "Enviar",
  Partners: "Socios",
  "Previous Chapter": "Capítulo anterior",
  "Next Chapter": "Siguiente capítulo",
  "Read the transcript": "Leer la transcripción",

  // intentions
  Flip: "Voltear",
  Close: "Cerrar",
  Share: "Compartir",
  "Artwork by ": "Obra de ",
  "Learn About the Artist": "Conoce al artista",
  "See more of their work at": "Ver más de su trabajo en",
  "Share Instagram Story": "Compartir en Instagram Stories",
  "Download and Share": "Descargar y compartir",
  Scroll: "Desplazar",
  "Equality Fund homepage": "Página de inicio del Fondo para la Igualdad",
  About: "Acerca de",

  // global
  "404 Page Not Found": "404 Página no encontrada",
  "Looks like there's nothing here.": "Parece que no hay nada aquí",

  // map
  Africa: "África",
  "Asia & Pacific": "Asia y Pacífico",
  Caribbean: "El Caribe",
  Europe: "Europa",
  "Latin America": "América Latina",
  "Middle East": "Oriente Medio",
  "Multi-Regional": "Multirregional",
  Region: "Región",
  "Organizations Funded": "Organizaciones Financiadas",
  "Amount Distributed in CAD": "Monto Distribuido en CAD",
  "Organizations Funded Directly": "Organizaciones Financiadas Directamente",
  "Feminist Funds": "Fondos Feministas",
  "Organizations funded via our sister funds":
    "Organizaciones financiadas a través de nuestros fondos hermanos",
};
