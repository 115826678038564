// french translations
export default {
  "Learn more": "En savoir plus",
  "Photo courtesy of": "Photo avec l'aimable autorisation de",
  Explore: "En savoir plus",
  Download: "télécharger",
  "Previous Story": "Article précédent",
  "Next Story": "Article suivant",
  "Expand Story": "Agrandir l'article",
  Country: "Pays",
  "Email Address": "Courriel",
  Submit: "Soumettre",
  Partners: "Partners",
  "Previous Chapter": "Chapitre précédent",
  "Next Chapter": "Chapitre suivant",
  "Read the transcript": "Lire la transcription",
  // intentions
  Flip: "Retourner",
  Close: "Fermer",
  Share: "Partager",
  "Artwork by ": "Œuvres d'art ",
  "Learn About the Artist": "Découvrez l'artiste",
  "See more of their work at: ": "Ver más de su trabajo en: ",
  "Share Instagram Story": "Partagez une histoire Instagram",
  "Download and Share": "Télécharger et partager",
  Scroll: "Défiler",
  "Equality Fund homepage": "Page d'accueil du Fonds Égalité",
  About: "À propos",

  // global
  "404 Page Not Found": "404 Página no encontrada",
  "Looks like there's nothing here.": "Parece que no hay nada aquí",

  // map
  Africa: "Afrique",
  "Asia & Pacific": "Asie et Pacifique",
  Caribbean: "Caraïbes",
  Europe: "Europe",
  "Latin America": "Amérique latine",
  "Middle East": "Moyen-Orient",
  "Multi-Regional": "Multi-régional",
  Region: "Région",
  "Organizations Funded": "Organisations financées",
  "Amount Distributed in CAD": "Montant distribué en CAD",
  "Organizations Funded Directly": "Organisations financées directement",
  "Feminist Funds": "Fonds féministes",
  "Organizations funded via our sister funds":
    "Organisations financées via nos fonds sœurs",
};
