import React, { useEffect, useState, useMemo } from "react";
import classNames from "classnames";
import useDimensions from "@hooks/useDimensions";
import { InView } from "@base";
import useTouchDevice from "@hooks/useTouchDevice";

const VimeoBackgroundVideo = ({
  videoId,
  aspectRatio,
  disableOnTouchDevice,
  className,
  timeout,
}) => {
  const ratio = aspectRatio ? aspectRatio[0] / aspectRatio[1] : 16 / 9;
  const isTouchDevice = useTouchDevice();
  const [el, dimensions] = useDimensions();
  const { width: elWidth = 0, height: elHeight = 0 } = dimensions || {};
  const [visible, setVisible] = useState(false);
  const [showing, setShowing] = useState(false);
  const [loaded, setLoaded] = useState(false);
  let width = elWidth;
  let height = width / ratio;
  if (height < elHeight) {
    height = elHeight;
    width = height * ratio;
  }

  useEffect(() => {
    // don't show for a few seconds to improve page load time
    setTimeout(
      () => {
        setShowing(true);
      },
      timeout ? 1500 : 0
    );
  }, []);

  const styleObj = useMemo(() => ({ width, height }), [width, height]);

  return (
    <div
      ref={el}
      className={classNames(
        "absolute inset-0 overflow-hidden transition duration-500",
        className,
        {
          "opacity-0": !loaded || !showing,
        }
      )}
    >
      <InView
        className="h-full w-full"
        onEnter={() => setVisible(true)}
        unobserveAfterEntry
      >
        {!(isTouchDevice && disableOnTouchDevice) && showing && (
          <iframe
            title="textural video"
            src={
              visible
                ? `https://player.vimeo.com/video/${videoId}?background=1`
                : ""
            }
            allow="autoplay; fullscreen"
            className="absolute left-[50%] top-[50%] h-full w-full -translate-x-1/2 -translate-y-1/2 transform"
            style={styleObj}
            onLoad={() => setLoaded(true)}
          />
        )}
      </InView>
    </div>
  );
};

VimeoBackgroundVideo.defaultProps = {
  disableOnTouchDevice: false,
  className: "",
  style: {},
};

export default VimeoBackgroundVideo;
