const resolveImage = image => {
  const isArray = Array.isArray(image);
  const _image = isArray ? image[0] : image;
  if (image && _image) {
    const {
      status,
      title,
      url,
      height,
      width,
      hasFocalPoint,
      focalPoint,
      attribution,
    } = _image;
    return {
      status,
      title,
      url,
      height,
      width,
      focalPoint: hasFocalPoint ? focalPoint : false,
      attribution,
    };
  }
  return null;
};

export default resolveImage;
