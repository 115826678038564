import React, { useState } from "react";
import { AppLink } from "@base";
import { Icon } from "@atoms";
import classNames from "classnames";

const Socials = ({ socials, backgroundState, color }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [background] = backgroundState || useState(color);
  return (
    <ul>
      <li className="flex gap-2">
        {socials?.map((s, i) => {
          const { platform, accounturl, url } = s;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <AppLink
              key={s.url}
              to={accounturl || url}
              className={classNames(
                "flex h-5 w-5 items-center justify-center rounded-full p-1 text-white",
                {
                  "bg-black": background !== "black" || color === "black",
                  "bg-viridian": background === "black" || color === "viridian",
                }
              )}
              ariaLabel={`Go to Equality Fund's ${platform} page`}
            >
              <Icon
                name={platform}
                className="duration-400 h-3 transition"
                fitHeight={platform === "facebook"}
              />
            </AppLink>
          );
        })}
      </li>
    </ul>
  );
};

Socials.defaultProps = {};

export default Socials;
